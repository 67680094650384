import { Link, navigate } from 'gatsby';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import { FabricaFormulari } from './Formulari';
import { Carregant } from './Carregant';
import { ParàmetresDEntrada, usaUsuari } from './Usuari';

const { Formulari, Camp, BotóDEnviar, ContextValors } = FabricaFormulari<ParàmetresDEntrada>();

export const Entrar: FunctionComponent<{}> = () => {
  const { usuari, entra } = usaUsuari();

  useEffect(() => {
    if (usuari) void navigate('/socis');
  }, [!!usuari]);

  if (usuari !== null) return <Carregant/>

  return (
    <Formulari envia={entra}>
      <Stack gap={3}>
        <Form.Group>
          <Form.Label>Correu electrònic</Form.Label>
          <Camp nom="correu_electrònic" obligatori tipus="email" ocupant="Correu electrònic"/>
        </Form.Group>
        <Form.Group>
          <Form.Label className="d-flex justify-content-between">
            <span>Contrasenya</span>
            <span className="text-end">
              <ContextValors.Consumer>
                {({ 'correu_electrònic': correuElectrònic}) => (
                  <Link to="/recuperar-contrasenya" state={{ correuElectrònic }}>
                    Has oblidat la teva contrasenya?
                  </Link>
                )}
              </ContextValors.Consumer>
            </span>
          </Form.Label>
          <Camp nom="contrasenya" obligatori tipus="password" ocupant="Contrasenya"/>
        </Form.Group>
        <BotóDEnviar>
          Entrar
        </BotóDEnviar>
      </Stack>
    </Formulari>
  );
};
